import React, { FC } from 'react';
import { IVideo } from './HeroVideo.types';

//components
import { Stack } from 'src/components-v2/Layout';
import { AnimatedVideo } from 'src/components-v2/Media';

const HeroVideo: FC<IVideo> = ({ sources }) => {
  return (
    <Stack
      justifyContent='center'
      sx={{
        height: '100%',
      }}
    >
      <AnimatedVideo
        sx={{ height: 'auto', width: '100%' }}
        width={810}
        height={575}
        sources={sources}
        isLazy={false}
      />
    </Stack>
  );
};

export default HeroVideo;
