import React, { FC } from 'react';
import { IHeroTextColumn } from './HeroTextColumn.types';

//components
import { Stack } from 'src/components-v2/Layout';
import { Button } from 'src/components-v2/Inputs';

import { Typography } from 'src/components-v2/DataDisplay';

const HeroTextColumn: FC<IHeroTextColumn> = ({
  label,
  buttons,
  title,
  description,
}) => {
  return (
    <Stack
      flexDirection='column'
      justifyContent='center'
      sx={{
        height: '100%',
        mb: { xs: '2.4rem', sm: 0 },
        position: 'relative',
        zIndex: '1',
        '& h1': {
          mb: { xs: '2.4rem', sm: '3.2rem', md: '4rem' },
        },
        '& h2': {
          mb: { xs: '2rem', md: '3.2rem' },
        },
        '& p': {
          mb: { xs: '2rem', md: '3.2rem' },
        },
      }}
    >
      {label?.value && (
        <Typography
          variant='h6'
          component='h1'
          sx={{
            mt: { xs: '0.4rem', md: '0.2rem' },
            mb: 0,
            color: label?.color ? label.color : 'black700',
          }}
        >
          {label.value}
        </Typography>
      )}
      <Typography
        variant='h1'
        component='h2'
        sx={{
          fontWeight: '400',
        }}
      >
        {title}
      </Typography>
      <Typography variant='h5' component='p'>
        <b>{description}</b>
      </Typography>
      <Stack
        direction='row'
        sx={{
          gap: { xs: '15px', sm: '0px', md: '16px' },
        }}
      >
        {buttons.map((button) => {
          return <Button {...button}>{button.children}</Button>;
        })}
      </Stack>
    </Stack>
  );
};

export default HeroTextColumn;
