import React from 'react';
import Globe from 'plaid-threads/Icons/Globe';
import User from 'plaid-threads/Icons/User';
import Lightning from 'plaid-threads/Icons/Lightning';
import Terminal from 'plaid-threads/Icons/Terminal';
import Build from 'plaid-threads/Icons/Build';
import Document from 'plaid-threads/Icons/Document';
import Libraries from 'plaid-threads/Icons/Libraries';
import External from 'plaid-threads/Icons/External';
import Pin from 'plaid-threads/Icons/Pin';
import Webhooks from 'plaid-threads/Icons/Webhooks';
import ItemManagement from 'plaid-threads/Icons/ItemManagement';
import Products from 'plaid-threads/Icons/Products';
import { ButtonVariants } from 'src/components-v2/Inputs/Button/Button.types';
import { Link } from 'src/components/Inputs';
import { MainTemplate } from 'src/templates';
import { Locales } from 'src/lib/constants';
import { Image, AnimatedVideo } from 'src/components-v2/Media';
import { Stack, Column, Box, Spacer, Section } from 'src/components-v2/Layout';
import { LogoWall, FeatureCarousel, CardsRow } from 'src/sections';
import { Typography } from 'src/components-v2/DataDisplay';
import { Wrapper, FeatureStack, Stat } from 'src/containers';
import { HeroVideo, HeroTextColumn } from 'src/sections/Heroes/ProductHero';
import Cta from 'src/components/cta';

const IdvProductPage = ({ locale = Locales.EN_US }) => {
  return (
    <MainTemplate {...metaData} locale={locale} {...mainTemplateStyles}>
      <Spacer
        sx={{
          height: { xs: '25px', sm: '50px', xl: '100px' },
        }}
      />

      <Wrapper component='header'>
        <Column xs={24} sm={10} lg={7} lgOffset={2}>
          <HeroTextColumn {...productHeroText} />
        </Column>
        <Column xs={24} smOffset={1} sm={13} lg={12} lgOffset={1}>
          <HeroVideo
            sources={[
              {
                src:
                  'https://videos.ctfassets.net/ss5kfr270og3/4BGdUjN7OEZBgzCOQhR0TU/6fe070578153da1b8e84bbb1106b713c/IDV_Hero-hevc.mov',
                type: 'video/quicktime',
              },
              {
                src:
                  'https://videos.ctfassets.net/ss5kfr270og3/4oS7znEqmN9ncJyuM5EBeY/565a948aa0bda4906a55ae5eefdd1bc0/IDV_Hero.webm',
                type: 'video/webm',
              },
            ]}
          />
        </Column>
      </Wrapper>
      <Spacer height={200} />
      <CardsRow {...features} />
      <Spacer height={200} />
      <FeatureCarousel
        items={protectYourBottomLineCarousel.items}
        interval={10000}
      >
        <Section component='header'>
          <Typography variant='h3'>
            {protectYourBottomLineCarousel.title}
          </Typography>
        </Section>
        <Typography variant='p'>
          {protectYourBottomLineCarousel.description}
        </Typography>
      </FeatureCarousel>
      <Spacer height={200} />

      <CardsRow {...stats} />
      <Spacer height={200} />

      <Wrapper>
        <Column xs={24} sm={12} lg={9} lgOffset={2}>
          <Stack
            justifyContent='center'
            sx={{
              height: '100%',
              '& h3': {
                mb: { xs: '1.6rem', md: '0.8rem' },
              },
              '& p': {
                mb: { xs: '3.2rem', sm: '4rem', lg: '5.6rem' },
              },
              '& div > p': {
                // for decorative text
                mb: { xs: '0.8rem', lg: '1.6rem' },
              },
            }}
          >
            <Section component='header'>
              <Typography variant='h3'>More coverage, more users</Typography>
            </Section>
            <Typography variant='p'>
              From the front-end UI to the back-end data sources, Identity
              Verification is built to quickly verify users, no matter where
              they’re from.
            </Typography>
            <Box>
              <Stack flexDirection='row' gap='8px'>
                <Document />
                <Typography variant='p' sx={{ flex: 1 }}>
                  User experience optimized to improve automated verifications
                  by as much as 30%
                </Typography>
              </Stack>
              <Stack flexDirection='row' gap='8px'>
                <Pin />
                <Typography variant='p' sx={{ flex: 1 }}>
                  Available in English, Español, Français, Português, or 日本語
                </Typography>
              </Stack>
              <Stack flexDirection='row' gap='8px'>
                <Libraries />
                <Typography variant='p' sx={{ flex: 1 }}>
                  Dedicated data source coverage for unbanked and thin-file
                  consumers
                </Typography>
              </Stack>
              <Stack flexDirection='row' gap='8px'>
                <Webhooks />
                <Typography variant='p' sx={{ flex: 1 }}>
                  Support for rarer ID documents, such as permanent residency
                  cards and B1/B2 temporary visas
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Column>
        <Column xs={24} sm={11} smOffset={1} lg={10} lgOffset={1}>
          <Stack sx={{ height: '100%', justifyContent: 'center' }}>
            <Image
              width={670}
              height={700}
              isLazy={true}
              src={
                'https://images.ctfassets.net/ss5kfr270og3/3MYovAf9jCzMzDeyjFLBD9/d83f805d08fe4ff7723e476ee74bb215/document_verification.png' +
                '?fm=webp&w=1340&q=70'
              }
              alt='document verification'
              sources={[
                {
                  media: '(max-width: 639px)',
                  srcSet:
                    'https://images.ctfassets.net/ss5kfr270og3/3MYovAf9jCzMzDeyjFLBD9/d83f805d08fe4ff7723e476ee74bb215/document_verification.png' +
                    '?fm=webp&w=650&q=70',
                },
                {
                  media: '(min-width: 640px)',
                  srcSet:
                    'https://images.ctfassets.net/ss5kfr270og3/3MYovAf9jCzMzDeyjFLBD9/d83f805d08fe4ff7723e476ee74bb215/document_verification.png' +
                    '?fm=webp&w=1340&q=70',
                },
              ]}
            />
          </Stack>
        </Column>
      </Wrapper>
      <Spacer height={200} />

      <FeatureCarousel items={manageKYCCarousel.items} interval={10000}>
        <Section component='header'>
          <Typography variant='h3'>Manage KYC, without writing code</Typography>
        </Section>
        <Typography variant='p'>
          Easy for Product, Compliance, and Risk teams - from set up to ongoing
          management.
        </Typography>
      </FeatureCarousel>
      <Spacer height={200} />
      <CardsRow {...plaidNetwork} />
      <Spacer height={200} />
      <Spacer height={54} sx={{ backgroundColor: 'black' }} />
      <Wrapper sx={{ backgroundColor: 'black' }}>
        <Column xs={24} sm={12} lg={9} lgOffset={2}>
          <Stack
            direction='column'
            textAlign={{ xs: 'center', sm: 'left' }}
            justifyContent='center'
            sx={{
              height: '100%',
              '& h2': { mb: { xs: '2.4rem', sm: '3.2rem', lg: '4.0rem' } },
            }}
          >
            <Section component='header'>
              <Typography
                variant='h2'
                sx={{
                  color: 'white',
                  textAlign: { xs: 'center', sm: 'left' },
                }}
              >
                Experience Plaid Identity Verification
              </Typography>
            </Section>
            <Link
              href='https://plaid.com/docs/identity-verification/'
              variant='cta'
              color='white'
            >
              <a>View API docs</a>
            </Link>
          </Stack>
        </Column>
        <Column xs={24} sm={11} smOffset={1} lg={10} lgOffset={1}>
          <Stack
            justifyContent='center'
            sx={{
              height: '100%',
            }}
          >
            <AnimatedVideo
              sx={{ mt: 'auto', height: 'auto', width: '100%' }}
              width={670}
              height={825}
              isLazy={true}
              sources={[
                {
                  src:
                    'https://videos.ctfassets.net/ss5kfr270og3/17EmG7iRLJMybSWJ51U01v/f09b06e89d6f911072821ce5a934f200/idv-demo-hevc.mov',
                  type: 'video/quicktime',
                },
                {
                  src:
                    'https://videos.ctfassets.net/ss5kfr270og3/33F4zXlsg1jGd2yqDqBIqP/59f73db7b652626b4967eccf895697b8/idv-demo.webm',
                  type: 'video/webm',
                },
              ]}
            />
          </Stack>
        </Column>
      </Wrapper>
      <Spacer height={54} sx={{ backgroundColor: 'black' }} />
      <Spacer
        sx={{
          height: { xs: '75px', md: '150px' },
        }}
      />
      <LogoWall images={productLogos} />
      <Spacer height={200} />

      <CardsRow {...integrationOptions} />
      <Spacer
        sx={{
          height: { lg: '100px' },
        }}
      />

      <Cta {...ctaData} />
      <Spacer height={200} />
    </MainTemplate>
  );
};

export default IdvProductPage;

// static content data
const metaData = {
  'meta-title': 'Identity Verification - Global IDV to Ensure KYC Compliance',
  'meta-description':
    'Verify your users’ identities, around the world. Ensure know your customer (KYC) and customer due diligence (CDD) compliance while improving conversion rates.',
  hasFooterCta: false,
};

const productHeroText = {
  label: {
    color: 'black800',
    value: 'IDENTITY VERIFICATION',
  },
  title: (
    <>
      More customers,{' '}
      <b>
        <i>minus the fraudsters</i>
      </b>
    </>
  ),
  description: 'Verify good users and stop bad ones in seconds.',
  buttons: [
    {
      variant: 'contained' as ButtonVariants,
      children: <>Contact us</>,
      href: '#contact-form',
      sx: { color: 'white !important' },
    },
    {
      variant: 'text' as ButtonVariants,
      children: <>Get started</>,
      href: 'https://dashboard.plaid.com/signup',
    },
  ],
};

const features = {
  cards: [
    <FeatureStack
      icon={{ component: <Lightning />, color: 'purple800' }}
      title='Verify identity data'
      description='Confirm identity information against regulated data sources in seconds.'
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/5SqJ0Fc8T5aMZrWcD7GtF9/964ffeb89aa10b112af7e49107b8e7a8/idv-card-1.png?fm=webp&w=792&q=70'
        alt='lightning verification'
        width='396'
        height='464'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/5SqJ0Fc8T5aMZrWcD7GtF9/964ffeb89aa10b112af7e49107b8e7a8/idv-card-1.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/5SqJ0Fc8T5aMZrWcD7GtF9/964ffeb89aa10b112af7e49107b8e7a8/idv-card-1.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,
    <FeatureStack
      icon={{ component: <Globe />, color: 'purple800' }}
      title='Authenticate IDs'
      description='Verify that global ID documents are authentic and valid.'
    >
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/4OONAklwf92d04rjMfSRLc/630457cef15117c231773ff5537a295b/idv-card-2.png?fm=webp&w=792&q=70'
        alt='california driver license'
        width='396'
        height='464'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/4OONAklwf92d04rjMfSRLc/630457cef15117c231773ff5537a295b/idv-card-2.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/4OONAklwf92d04rjMfSRLc/630457cef15117c231773ff5537a295b/idv-card-2.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,

    <FeatureStack
      icon={{ component: <User />, color: 'purple800' }}
      title='Confirm liveness'
      description='Ensure a person is present and their face matches the image on the provided ID.'
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/4rRJ2xjIGm27ukMLIuifjA/df544b4dcfa7d146fedbce60bb35b558/MS1-3-US_2x.png?fm=webp&w=792&q=70'
        alt='liveness detection'
        width='396'
        height='464'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/4rRJ2xjIGm27ukMLIuifjA/df544b4dcfa7d146fedbce60bb35b558/MS1-3-US_2x.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/4rRJ2xjIGm27ukMLIuifjA/df544b4dcfa7d146fedbce60bb35b558/MS1-3-US_2x.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,
  ],
};

const plaidNetwork = {
  header: (
    <Typography variant='h3' component='h2'>
      Faster verification on the Plaid Network
    </Typography>
  ),
  cards: [
    <FeatureStack
      icon={{ component: <ItemManagement />, color: 'purple800' }}
      title='Autofill'
      description={`When users provide a phone number and date of birth, we autofill other details to speed up completion to under 10 seconds.`}
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
          height: 'auto',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/1OggXhMckOn5thhR7A4ElH/0663d15675902659b81d666ae8b75e61/confirm_information.png?fm=webp&w=1202&q=70'
        alt='autofill confirm information'
        width='601'
        height='558'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/1OggXhMckOn5thhR7A4ElH/0663d15675902659b81d666ae8b75e61/confirm_information.png?fm=webp&w=710&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/1OggXhMckOn5thhR7A4ElH/0663d15675902659b81d666ae8b75e61/confirm_information.png?fm=webp&w=1202&q=70',
          },
        ]}
      />
    </FeatureStack>,
    <FeatureStack
      icon={{ component: <Products />, color: 'purple800' }}
      title='Remember Me'
      description={`Say hello to “one-click” verification. Users can save their identity data to fast-track verification across Plaid-powered apps. `}
    >
      {' '}
      <AnimatedVideo
        sx={{ mt: 'auto', height: 'auto', width: '100%' }}
        width={601}
        height={558}
        isLazy={true}
        sources={[
          {
            src:
              'https://videos.ctfassets.net/ss5kfr270og3/JMaVSo9LWfKuRYKiiotyb/15b6a4e37f32de7dfe505e3339d5fcb1/IDV_Remember_me-hevc_2x.mov',
            type: 'video/quicktime',
          },
          {
            src:
              'https://videos.ctfassets.net/ss5kfr270og3/7KeTo4xq7cJHyH16p7o27y/8097dc5f2f577a30cfd39b61bad4a3db/IDV_Remember_me_2x.webm',
            type: 'video/webm',
          },
        ]}
      />
    </FeatureStack>,
  ],
};

const integrationOptions = {
  header: (
    <Typography variant='h3' component='h2'>
      Deploy out-of-the-box or integrate flexibly
    </Typography>
  ),
  cards: [
    <FeatureStack
      icon={{ component: <External />, color: 'purple800' }}
      title='No-code'
      description={`Start verifying identities with one click. Generate verification links from the Plaid dashboard, no integration needed.`}
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/eU68WiyFKhas37pzmiO2h/97f19be55c1fee8a9c72d2ad19c45854/no_code.png?fm=webp&w=792&q=70'
        alt='no code: verify.plaid.com'
        width='396'
        height='360'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/eU68WiyFKhas37pzmiO2h/97f19be55c1fee8a9c72d2ad19c45854/no_code.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/eU68WiyFKhas37pzmiO2h/97f19be55c1fee8a9c72d2ad19c45854/no_code.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,
    <FeatureStack
      icon={{ component: <Terminal />, color: 'purple800' }}
      title='Hybrid'
      description={`Verify users via API and dynamically handle fallbacks and exceptions with our UI.`}
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/6hd8zaQupqdw23KRz396dS/5e1773b89014215fd984f0ee31386596/hybrid.png?fm=webp&w=792&q=70'
        alt='hybrid via api and ui'
        width='396'
        height='360'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/6hd8zaQupqdw23KRz396dS/5e1773b89014215fd984f0ee31386596/hybrid.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/6hd8zaQupqdw23KRz396dS/5e1773b89014215fd984f0ee31386596/hybrid.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,
    <FeatureStack
      icon={{ component: <Build />, color: 'purple800' }}
      title='Fully integrated'
      description={`Use our conversion-optimized, embeddable interface for all aspects of user verification.
      `}
    >
      {' '}
      <Image
        sx={{
          mt: 'auto',
          width: '100%',
        }}
        src='https://images.ctfassets.net/ss5kfr270og3/6GM1cWuJgCi9N4mDOXNVWw/78f84a9c0779e419fa012bc9d8b4e144/fully_integrated.png?fm=webp&w=792&q=70'
        alt='fully integrated: basic information'
        width='396'
        height='360'
        isLazy={true}
        sources={[
          {
            media: '(max-width: 639px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/6GM1cWuJgCi9N4mDOXNVWw/78f84a9c0779e419fa012bc9d8b4e144/fully_integrated.png?fm=webp&w=476&q=70',
          },
          {
            media: '(min-width: 640px)',
            srcSet:
              'https://images.ctfassets.net/ss5kfr270og3/6GM1cWuJgCi9N4mDOXNVWw/78f84a9c0779e419fa012bc9d8b4e144/fully_integrated.png?fm=webp&w=792&q=70',
          },
        ]}
      />
    </FeatureStack>,
  ],
};

const productLogos = [
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/1rA2W3skeU2yC4mFR8Xyaf/d713ef1663861d133ed2fa5105565562/_Logos.png',
    alt: 'Credit Genie logo',
    width: 160,
    height: 30,
    isLazy: true,
  },
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/4JcoKLWfroHI4MssPkZHSR/7fa1849d746660df7ff96c5afa537338/current_logo.png',
    alt: 'Current logo',
    width: 160,
    height: 30,
    isLazy: true,
  },
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/2W9zCbeJIjshZq4HcRQlfu/28c7fb1bbb4b33a8dd09b24c1fa96c6f/veridian.png',
    alt: 'Veridian Credit Union logo',
    width: 106,
    height: 34,
    isLazy: true,
  },
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/7mKWtDfKSUHjXYbJCnP7kL/48db864fc2fb9f42da97f6e86e2b2178/carputty.png',
    alt: 'Carputty logo',
    width: 144,
    height: 34,
    isLazy: true,
  },
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/3Pb5S5qdNuAkgBDTlk8Spl/5121000b250c64a74c519b3381a56a75/gerald.png',
    alt: 'Gerald logo',
    width: 108,
    height: 24,
    isLazy: true,
  },
  {
    src:
      'https://images.ctfassets.net/ss5kfr270og3/3hNe8D5W5HBmpIIucv04HK/b0f6edaeac5d9f9e5556d58d7ff8b5a2/Company_Cardless__Colorspace_Grayscale.png',
    alt: 'Cardless logo',
    width: 144,
    height: 30,
    isLazy: true,
  },
];

const protectYourBottomLineCarousel = {
  title: 'Protect your bottom line',
  description:
    'Identity Verification prevents sophisticated attacks and reduces fraud losses with an anti-fraud engine that analyzes hundreds of risk signals.',
  items: [
    {
      content: (
        <>
          <Typography variant='p2'>
            <b>Behavioral analytics</b>
          </Typography>
          <Typography variant='p2'>
            Monitor how users enter PII and catch behavior consistent with bad
            actors, fraud rings, and bots.
          </Typography>
        </>
      ),
      aria: {
        label:
          'Behavioral analytics. Monitor how users enter their PII and catch behavior consistent with bad actors, fraud rings, and bots',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/3HIWqeZvlVmoRJFnBBbIWw/c7266e2f744d1db9bfef013945c6cc7c/MS1-1-US_2x.png',
        alt: 'behavioral analytics',
        width: '670',
        height: '760',
        isLazy: true,
      },
    },
    {
      content: (
        <>
          <Typography variant='p2'>
            <b>Email and phone risk</b>
          </Typography>
          <Typography variant='p2'>
            Check if the number and address are new, disposable, registered with
            external accounts, or compromised in breaches.
          </Typography>
        </>
      ),
      aria: {
        label:
          'Email and phone risk. Check if the number and address are new, disposable, registered with external accounts or compromised in breaches.',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/3R95W9AQY86fFf6X1kVhcz/31fa66cb60c6ede2e9ecc325cd960515/MS1-2-US_2x.png',
        alt: 'email and phone risk',
        width: '670',
        height: '760',
        isLazy: true,
      },
    },
    {
      content: (
        <>
          <Typography variant='p2'>
            <b>Device and network risk</b>
          </Typography>
          <Typography variant='p2'>
            Detect the use of VPN or Tor, IP geolocation and timezone mismatch,
            incognito browsers, or repeat sessions from the same device.
          </Typography>
        </>
      ),
      aria: {
        label:
          'Device and network risk: Detect the use of VPN or Tor, IP geolocation and timezone mismatch, incognito browsers, or repeat sessions from the same device.',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/6Mg8dixkEyUFuyqe75JGLV/49cc1854b767dbf202459d6ac7af7b0e/MS1-3-US_2x.png',
        alt: 'device and network risk',
        width: '670',
        height: '760',
        isLazy: true,
      },
    },
    {
      content: (
        <>
          <Typography variant='p2'>
            <b>Synthetic and stolen identities</b>
          </Typography>
          <Typography variant='p2'>
            Understand whether identity information is fabricated, manipulated,
            or stolen.
          </Typography>
        </>
      ),
      aria: {
        label:
          'Synthetic and stolen identities: Understand whether identity information is fabricated, manipulated or stolen.',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/288lo8vgadtP8tSxrju6Ry/a5cc4fadaab65604d4941f038c54df25/MS1-4-US_2x.png',
        alt: 'synthetic and stolen identities',
        width: '670',
        height: '760',
        isLazy: true,
      },
    },
  ],
};

const manageKYCCarousel = {
  items: [
    {
      content: (
        <Typography variant='p2'>
          <b>No-code editor to create and change verification flows</b>
        </Typography>
      ),
      aria: {
        label: 'No-code editor to create and change verification flows',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/1FcEeyosg47LmQMER8lx7N/97d1294b44dd025b29fad80cca6b5ffb/Workflow_management.png',
        alt: 'workflow management',
        width: '670',
        height: '561',
        isLazy: true,
      },
    },
    {
      content: (
        <Typography variant='p2'>
          <b>
            Customize risk rules and success criteria to your tolerance levels
          </b>
        </Typography>
      ),
      aria: {
        label:
          'Customize risk rules and success criteria to your tolerance levels',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/1UrAvWJxAtztgCLKZgXGif/496c3d00705e94084679159dbbd8581c/Risk_rules.png',
        alt: 'risk rules',
        width: '670',
        height: '561',
        isLazy: true,
      },
    },
    {
      content: (
        <>
          <Typography variant='p2'>
            <b>
              Comprehensive user and session information for record keeping,
              investigations, and future audits
            </b>
          </Typography>
        </>
      ),
      aria: {
        label:
          'Comprehensive user and session information for record keeping  investigations, and future audits',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/5llmQsIkBEFV9nZKYY5O4G/140bed6051da409b3273a1011ec0073e/User_session_info.png',
        alt: 'user session info',
        width: '670',
        height: '561',
        isLazy: true,
      },
    },
    {
      content: (
        <Typography variant='p2'>
          <b>
            Compliance agent dashboard to override failures and handle
            exceptions
          </b>
        </Typography>
      ),
      aria: {
        label:
          'Compliance Agent Dashboard to override failures and handle exceptions',
      },
      image: {
        src:
          'https://images.ctfassets.net/ss5kfr270og3/357FZwibiiHGf56b7NzoMq/c4f5f0e3fd4e98ba0b20f75d0f19b93d/Compliance_agent_dashboard.png',
        alt: 'compliance agent dashboard',
        width: '670',
        height: '561',
        isLazy: true,
      },
    },
  ],
};

const mainTemplateStyles = {
  sx: {
    backgroundImage:
      'url("https://images.ctfassets.net/ss5kfr270og3/3CY8O4qjrotIAZxC1bXmlV/a1579d42357d5a6694ab06fc866448ad/brushstroke_2x.png?fm=webp&w=3168&q=1")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: {
      xs: '140%',
      sm: '113%',
      lg: '92%',
    },
    backgroundPosition: { xs: '-4% 4%', sm: '45vw 0' },
    bgcolor: 'black200',
  },
  navbarStyles: { sx: { bgcolor: 'black200' } },
};

const stats = {
  cards: [
    <Stat backgroundImagePosition='left'>
      <Typography
        variant='h2'
        component='h3'
        sx={{
          textAlign: 'center',
        }}
      >
        10s
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant='p2'>
        Verify identities in as little as 10 seconds
      </Typography>
    </Stat>,
    <Stat backgroundImagePosition='center'>
      <Typography
        variant='h2'
        component='h3'
        sx={{
          textAlign: 'center',
        }}
      >
        90%+
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant='p2'>
        Consistently achieve pass rates of 90% or greater
      </Typography>
    </Stat>,
    <Stat backgroundImagePosition='right'>
      <Typography
        variant='h2'
        component='h3'
        sx={{
          textAlign: 'center',
        }}
      >
        16k
      </Typography>
      <Typography sx={{ textAlign: 'center' }} variant='p2'>
        Support for 16,000+ ID types and 200 countries and territories
      </Typography>
    </Stat>,
  ],
};

const ctaData = {
  id: 'contact-form',
  'marketo-key': 2007,
  shouldForward: true,
  title: 'Questions about how Plaid can fit into your business?',
  'with-subtext': true,
  subtext: `Our Sales team can help you find the right solution. Fill out the form and we'll get in touch shortly.`,
  'subtext-class': 'h4 regular',
  'include-phone': true,
  hasOnPagePostSubmitContent: false,
  isNewGrid: true,
  redirectUrl: '/contact-thanks/',
};
